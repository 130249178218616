import React, { useState, startTransition } from "react";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };
  const handleClick = (event) => {
    startTransition(() => {
      navigate("/dashboard");
    });
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg py-lg-0 fixed-top px-lg-5 `}
        style={{ backgroundColor: "#000000" }}
      >
        <a href="/" className="navbar-brand ms-4 ms-lg-0">
          <img
            className=""
            src="/assets/img/agrani-logo.png"
            alt="Capital for Farmers"
            style={{ height: "40px" }}
          />
        </a>
        <div className="d-lg-none d-md-none px-2">
          <div className="d-flex">
            <div className=" ms-2">
              <a
                className="btn btn-outline-primary py-2 px-3 fs-6 text-decoration-none"
                onClick={handleClick}
                style={{
                  borderRadius: "5px",
                }}
              >
                Sign In
              </a>
            </div>
            <div className=" ms-2">
              <a
                className="btn text-white py-2 px-3 fs-6 text-decoration-none bg-orange"
                href="https://onboarding.agrani.io"
              >
                Create Account
              </a>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0 fs-6">
            <a href="/" className="nav-item nav-link  text-decoration-none">
              Home
            </a>
            <a
              href="/contact"
              className="nav-item nav-link active text-decoration-none"
            >
              Contact
            </a>
          </div>

          <div className="d-none d-lg-flex ms-2">
            <a
              className="btn btn-outline-primary py-2 px-3 fs-6 text-decoration-none"
              onClick={handleClick}
              style={{
                borderRadius: "5px",
              }}
            >
              Sign In
            </a>
          </div>
          <div className="d-none d-lg-flex ms-2">
            <a
              className="btn text-white py-2 px-3 fs-6 text-decoration-none bg-orange"
              href="https://onboarding.agrani.io"
            >
              Create Account
            </a>
          </div>
        </div>
      </nav>
      <section className="bg-white py-3 py-md-5 mt-5 fade-in">
        <div className="container mt-3">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-md-center">
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11 px-lg-0 px-md-0 px-3">
                  <h2 className="h1 mb-3">Get in touch</h2>
                  <p className="lead fs-6 text-secondary mb-lg-5 mb-md-5 mb-4">
                    We're always on the lookout to work with new clients. If
                    you're interested in working with us, please get in touch in
                    one of the following ways.
                  </p>
                  {/* Contact Info Section */}
                  <div className="d-flex mb-lg-5 mb-md-5 mb-4">
                    <div className="me-4 text-primary">
                      <span className="mdi mdi-map-marker-radius text-primary fs-1"></span>
                    </div>
                    <div>
                      <h4 className="mb-2">Address</h4>
                      <address className="mb-0 text-secondary fs-6">
                        1601, 16th Floor, World Trade Tower , Sector 16, Noida,
                        Uttar Pradesh
                      </address>
                    </div>
                  </div>
                  <div className="row mb-lg-5 mb-md-5 mb-4">
                    <div className="col-12 col-sm-6">
                      <div className="d-flex mb-lg-5 mb-md-5 mb-4 mb-sm-0">
                        <div className="me-4 text-primary">
                          <span className="mdi mdi-phone text-primary fs-2"></span>
                        </div>
                        <div>
                          <h4 className="mb-2">Phone</h4>
                          <p className="mb-0">
                            <a
                              className="link-secondary text-decoration-none fs-6"
                              href="tel:+18001029232"
                            >
                              18001029232
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="d-flex mb-0">
                        <div className="me-4 text-primary">
                          <span className="mdi mdi-email fs-2"></span>
                        </div>
                        <div>
                          <h4 className="mb-2">E-Mail</h4>
                          <p className="mb-0">
                            <a
                              className="link-secondary text-decoration-none fs-6"
                              href="mailto:info@leadsconnect.in"
                            >
                              info@leadsconnect.in
                            </a>
                          </p>
                          <p>
                            <a
                              className="link-secondary text-decoration-none fs-6"
                              href="mailto:info@agrani.io"
                            >
                              info@agrani.io
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Form Section */}
            <div className="col-12 col-lg-6">
              <div className="bg-white border rounded shadow-sm overflow-hidden">
                <form onSubmit={handleSubmit}>
                  <div className="row gy-2 gy-xl-3 p-4 p-xl-5">
                    <div className="col-12">
                      <label htmlFor="fullname" className="form-label fs-6">
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        name="fullname"
                        placeholder="Enter your full name"
                        value={formData.fullname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="email" className="form-label fs-6">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <span className="mdi mdi-email fs-5"></span>
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="phone" className="form-label fs-6">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <span className="mdi mdi-phone fs-5"></span>
                        </span>
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Enter your phone number"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="subject" className="form-label fs-6">
                        Subject <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="Enter the subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="message" className="form-label fs-6">
                        Message <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        placeholder="Write your message here"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          className="btn btn-primary btn-lg"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer --> */}
      <div
        className="container-fluid  text-white-50 footer mt-5 pt-5 wow fadeIn p-3"
        style={{ backgroundColor: "#000000" }}
        data-wow-delay="0.1s"
      >
        <div className="container py-lg-5 py-3">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 ">
              <img
                className="mb-4"
                src="/assets/img/agrani-logo.png"
                alt="Capital for Farmers"
                style={{ height: "40px" }}
              />
              <p className="fs-6 offwhite">
                To Connect Farm Value Chain for enabling sustainable, scalable
                and profitable agri-business ecosystem.
              </p>
              <div className="d-flex pt-2 ">
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-twitter fs-4"></span>
                </a>
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-facebook fs-4"></span>
                </a>
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-youtube fs-4"></span>
                </a>
                <a className="btn btn-square me-2" href="">
                  <span className="mdi mdi-linkedin fs-4"></span>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offwhite">
              <h5 className="text-light mb-4">Address</h5>
              <p>
                <span className="mdi mdi-map-marker-radius  me-2 fs-5"></span>
                Sector 16, Noida, Uttar Pradesh
              </p>
              <p>
                <span className="mdi mdi-phone  me-2 fs-5"></span>
                18001029232
              </p>
              <p>
                <span className="mdi mdi-email  me-2 fs-5"></span>
                info@leadsconnect.in
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;
                <a href="https://www.leadsconnect.in" target="_blank">
                  LeadsConnect Services Pvt. Ltd
                </a>
                , All Right Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
