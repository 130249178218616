import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "app/store";
import "./i18n";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "react-oidc-context";
const oidcConfig = {
  authority: "https://auth.agrani.tech/realms/agrani/",
  client_id: "agrani-website",
  redirect_uri: `${window.location.protocol}//${window.location.host}/dashboard`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
  revokeTokensOnSignout: true,
  skipSigninCallback: false,
  onSigninCallback: async (_user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
document.body.classList.toggle("sidebar-icon-only");
root.render(
  <Provider store={store}>
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <App />
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </BrowserRouter>
    </AuthProvider>
  </Provider>
);
