import CustomBreadCrumbs from "components/CustomBreadCrumbs";
import { Outlet } from "react-router-dom";
import Footer from "shared/Footer";
import Navbar from "shared/Navbar";
import SettingsPanel from "shared/SettingsPanel";
import Sidebar from "shared/Sidebar";

const Layout = () => {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <CustomBreadCrumbs />
          <div className="content-wrapper  ">
            {/* <BlankPage /> */}
            <Outlet />
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
